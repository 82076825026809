import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import HTMLLogo from '../assets/imgs/htmllogo.png';
import CSSLogo from '../assets/imgs/csslogo.png';
import BootstrapLogo from '../assets/imgs/bootstraplogo.png';
import JSLogo from '../assets/imgs/jslogo.png';
import ReactLogo from '../assets/imgs/reactlogo.png';
import NodeLogo from '../assets/imgs/nodejslogo.png';
import GithubLogo from '../assets/imgs/githublogo.png';
import ReduxLogo from '../assets/imgs/reduxlogo.png';
import SQLLogo from '../assets/imgs/sqllogo.png';
import SkillItem from './mini/SkillItem';

const Skills = () => {
  const skills = [
    { img: HTMLLogo, label: 'HTML' },
    { img: CSSLogo, label: 'CSS' },
    { img: JSLogo, label: 'Javascript' },
    { img: ReactLogo, label: 'React' },
    { img: ReduxLogo, label: 'Redux' },
    { img: BootstrapLogo, label: 'Bootstrap' },
    { img: NodeLogo, label: 'Node JS' },
    { img: SQLLogo, label: 'SQL' },
    { img: GithubLogo, label: 'Github' },
  ];

  const [showSkills, setShowSkills] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('Skills');
      const rect = section.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight - 100) {
        document.getElementById('skills-header').classList.add('slideup');
        document
          .getElementById('skills-header')
          .classList.remove('opacity-0-my');
        setShowSkills(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className='mt-5'>
      <div id='skills-header' className='opacity-0-my'>
        <h2 className={'roboto-black'}>
          <span className={'roboto-black header-text'}>SKILLS</span>

          <span className={'roboto-black text-secondary header-text'}>
            {' '}
            GAINED
          </span>
        </h2>

        <Card
          id='Skills'
          style={{ backgroundColor: '#1a1a1a' }}
          className='p-4 pb-2 text-white'
        >
          <div>
            {showSkills ? (
              <ul className='row m-0 p-0'>
                {skills.map((skill, index) => (
                  <SkillItem
                    key={skill.label}
                    img={skill.img}
                    label={skill.label}
                    index={index}
                  />
                ))}
              </ul>
            ) : null}
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Skills;
