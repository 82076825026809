import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa6';

const ExperienceCard = ({ company, description, timeframe }) => {
  const id = company.split(' ').join('-').toLowerCase();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById(id);
      const rect = section.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight - 100) {
        document.getElementById(id).classList.remove('opacity-0-my');
        document.getElementById(id).classList.add('slideup');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id={id} className='container-fluid opacity-0-my'>
      <div className='row exprience-card-my rounded-4 p-2 py-3'>
        <div className='col-8 text-secondary'>
          <h5 className='fs-5 roboto-bold text-white'>{company}</h5>
          <h6 className='roboto-regular'>{description}</h6>
          <p lassName='roboto-regular'>{timeframe}</p>
        </div>
        <div className='col-4 experience-status-my'>
          <div className=' ms-5 ps-3'>
            <FaCircle
              size={24}
              color={timeframe?.includes('Present') ? 'lightgreen' : '#dc3545'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
