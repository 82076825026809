import React, { useEffect } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { PiHammerLight } from "react-icons/pi";
import { SiSemanticscholar } from "react-icons/si";
import { IoFolderOpenOutline } from "react-icons/io5";
import { IoMailOpenOutline } from "react-icons/io5";
import NavItems from "./mini/NavItems";

const Nav = () => {
  useEffect(() => {
    const navbar = document.getElementById("nav-bar");

    const handleScroll = () => {
      const nav = document.getElementById("nav-bar-pill");
      if (nav) {
        if (window.scrollY > 100) {
          nav.classList.add("bg-dark-subtle-my");
          navbar.classList.add("margin-top-10-my");
        } else {
          nav.classList.remove("bg-dark-subtle-my");
          navbar.classList.remove("margin-top-10-my");
        }
      }
    };

    document.addEventListener("scroll", handleScroll);

    if (navbar) {
      navbar.classList.add("nav-bar-my");

      navbar.classList.remove("nav-bar-my-hide");
    }

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="nav-bar"
      style={{ zIndex: 1000 }}
      className="container nav-bar-my-hide position-fixed"
    >
      <div className="row mt-0">
        <div className=" col-md-3"></div>
        <div className="col-10 p-0 px-4 col-md-6 text-center  ">
          <div
            id="nav-bar-pill"
            className="d-flex bg-dark-subtle-mobile-my p-3 px-md-5 rounded-pill shadow align-items-center justify-content-between  w-75 m-auto"
          >
            <NavItems Icon={IoHomeOutline} label="Home" />
            <NavItems Icon={PiHammerLight} label="Skills" />
            <NavItems Icon={SiSemanticscholar} label="About" />
            <NavItems Icon={IoFolderOpenOutline} label="Projects" />
            <NavItems Icon={IoMailOpenOutline} label="Contact" />
          </div>
        </div>
        <div className=" col-md-3"></div>
      </div>
    </nav>
  );
};

export default Nav;
