import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

const About = () => {
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('About');
      const rect = section.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight - 100) {
        document.getElementById('about-header').classList.add('slideup');
        document
          .getElementById('about-header')
          .classList.remove('opacity-0-my');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className='mt-5' id='About'>
      <div id='about-header' className='opacity-0-my'>
        <h2 className={'roboto-black'}>
          <span className={'roboto-black header-text'}>ABOUT</span>

          <span className={'roboto-black text-secondary header-text'}>
            {' '}
            MYSELF
          </span>
        </h2>

        <Card style={{ backgroundColor: '#a3a380' }} className='p-4 text-white'>
          {/* list of thingsm about me with paragraph at first */}
          <p className='fs-3 roboto-bold'>About Me</p>
          <p style={{ textAlign: 'justify' }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi
            error repellendus quisquam dolorem ipsam accusantium vitae, unde
            pariatur perspiciatis dolor, maxime consectetur ex tempore nobis non
            nihil tempora. Dolorem, suscipit. Eveniet nam vero id et error quas
            autem tempora tenetur omnis, at est non iste, sapiente placeat
            corporis. Nam iste mollitia a deleniti placeat et sunt illo nemo eum
            odio. Minus nemo accusamus itaque blanditiis dolore magnam vitae ut
            voluptatum quasi. Iste, consequatur, explicabo est ipsam eligendi
            nihil, obcaecati sed et laudantium minima adipisci. Inventore
            voluptatum voluptatem repudiandae corporis ex! Deleniti itaque quo
            dolor explicabo iusto vero accusantium eaque esse voluptate sunt
            quis quas quia aut labore sed libero a doloribus ipsa magnam at
            illum laborum, blanditiis eius. Accusamus, corporis. Eligendi beatae
            perspiciatis rem rerum eius odit dolore assumenda ex ipsam a non
            sunt iste reprehenderit unde error fugiat, magni ut neque natus.
            Explicabo ad debitis doloribus earum repellendus architecto?
          </p>
        </Card>
      </div>
    </section>
  );
};

export default About;
