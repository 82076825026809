import React from "react";
import { Card } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="my-5" id="#Footer">
      <div className="py-4">
        <h2 className={"roboto-black gelatine"}>
          <span className={"roboto-black header-text"}>THANK</span>

          <span className={"roboto-black text-secondary header-text"}>
            {" "}
            YOU
          </span>
        </h2>
      </div>
    </footer>
  );
};

export default Footer;
