import React, { useEffect, useState } from 'react';

const SkillItem = ({ img, label, index }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, index * 200); // Delay each item by 200ms times its index
    return () => clearTimeout(timer); // Clean up the timer
  }, [index]);
  return (
    <>
      {show ? (
        <li
          className={`col-6 col-sm-6 col-lg-3 d-flex m-0 mb-2 mb-md-4 p-0 align-items-center gap-2 ${
            show ? 'gelatine' : ''
          }`}
        >
          <img height={25} src={img} alt={label + '-logo'} />
          <p className='fs-5 roboto-medium'>{label}</p>
        </li>
      ) : null}
    </>
  );
};

export default SkillItem;
