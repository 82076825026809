import React from "react";

const NavItems = ({ Icon, label, handleClick }) => {
  const scrollIntoView = (id) => {
    document.getElementById(`${id}`).scrollIntoView({
      behavior: "smooth",
      block: id !== "Skills" ? "start" : "center",
    });
  };
  return (
    <div
      className="nav-Items-my"
      onClick={
        handleClick
          ? handleClick
          : () => {
              scrollIntoView(label);
            }
      }
    >
      <Icon size={25} />
      <p className="mt-1 nav-text-my text-lg">{label}</p>
    </div>
  );
};

export default NavItems;
