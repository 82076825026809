import React from 'react';
import { Card } from 'react-bootstrap';

import BlueLines from '../assets/imgs/line-blue.png';
import RedLines from '../assets/imgs/line-red.png';
import Memby from '../assets/imgs/memby.jpg';

import { AiOutlineFacebook } from 'react-icons/ai';
import { VscGithub } from 'react-icons/vsc';
import { CiLinkedin } from 'react-icons/ci';
import { MdAlternateEmail } from 'react-icons/md';

import IconButton from './mini/IconButton';
import { socialUrls } from '../utils/urls';

const ProfileCard = () => {
  return (
    <Card className='position-relative w-75 m-0 p-0 rounded-4 gelatine'>
      <img
        className='position-absolute  w-50  start-0 top-0'
        src={BlueLines}
        alt=''
      />
      <Card.Img className='m-4 mb-2 w-auto text-center rounded-4' src={Memby} />
      <Card.Title className='mt-2 text-center roboto-black fs-3 text-danger'>
        Memby Poudel
      </Card.Title>
      {/* <div className="position-relative mt-4"> */}
      <img
        className='position-absolute w-100  start-0 red-lines-profile'
        src={RedLines}
        alt=''
      />
      {/* </div> */}
      <Card.Body
        style={{ fontSize: 17, fontWeight: 'bold' }}
        className='w-100  p-3 pt-md-5 text-center text-secondary roboto-medium mt-4'
      >
        A JavaScript Developer who has crafted multiple applications.
      </Card.Body>

      <Card.Footer className='text-center mt-4 border-0 bg-white rounded-4'>
        <div className='d-flex gap-3 justify-content-center align-items-center'>
          <IconButton
            icon={AiOutlineFacebook}
            onClick={() => {
              window.open(socialUrls.facebook, '_blank');
            }}
          />
          <IconButton
            icon={VscGithub}
            onClick={() => {
              window.open(socialUrls.github, '_blank');
            }}
          />
          <IconButton
            icon={CiLinkedin}
            onClick={() => {
              window.open(socialUrls.linkedin, '_blank');
            }}
          />
          <IconButton
            icon={MdAlternateEmail}
            onClick={() => {
              window.open(socialUrls.email);
            }}
          />
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ProfileCard;
