import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ExperienceCard from './mini/ExperienceCard';

const Exprience = () => {
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('Exprience');
      const rect = section.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight - 100) {
        document
          .getElementById('experience-header')
          .classList.remove('opacity-0-my');
        document.getElementById('experience-header').classList.add('slideup');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className='mt-5' id='Exprience'>
      <div id='experience-header' className='  opacity-0-my'>
        <h2 className={'roboto-black'}>
          <span className={'roboto-black header-text'}>2+ YEARS OF</span>
          <br></br>

          <span className={'roboto-black text-secondary header-text'}>
            EXPERIENCE
          </span>
        </h2>
      </div>
      <ExperienceCard
        company={'Itahari International College'}
        description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi
            error repellendus quisquam dolorem ipsam accusantium vitae, unde
            pariatur`}
        timeframe={'JUL 2024 - Present'}
      />
      <br />
      <ExperienceCard
        company={'ITSoch Pvt. Ltd.'}
        description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi
            error repellendus quisquam dolorem ipsam accusantium vitae, unde
            pariatur`}
        timeframe={'JUN 2022 - JUN 2024'}
      />
    </section>
  );
};

export default Exprience;
