import React from "react";

const IconButton = ({ icon: Icon, onClick, color, size }) => {
  return (
    <div>
      <button onClick={onClick} className="p-0 border-0 bg-white">
        {<Icon size={size ?? 20} color={color ?? "#dc3545"} />}
      </button>
    </div>
  );
};

export default IconButton;
