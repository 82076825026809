import React, { useEffect, useState } from 'react';
import Nav from './components/Nav';
import Home from './components/Home';
import ProfileCard from './components/ProfileCard';
import Skills from './components/Skills';
import About from './components/About';
import Footer from './components/Footer';
import Exprience from './components/Exprience';
import { Alert } from 'react-bootstrap';

const Layout = () => {
  const [hideConstruction, setHideConstruction] = useState(false);

  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    if (timer === 1) {
      setHideConstruction(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <>
      <div className='container'>
        <Nav />
        <br />
        <div className='mt-md-5' />
        {hideConstruction ? null : (
          <Alert
            style={{ zIndex: 1000 }}
            variant='info'
            className='text-center'
          >
            {`| ${timer}s | `} This site is currently under construction. Some
            features may not work as expected.
          </Alert>
        )}
        <div className='row' id='Home'>
          <div className='col-lg-1'></div>
          <div className='col-lg-10'>
            <div className='container-fluid'>
              <div className='row'>
                {/* <div className='col-lg-1'></div> */}
                <div className='col-lg-4 mt-5 p-0'>
                  <div className='position-relative w-75'>
                    <div className='profile-card-wrapper'>
                      <ProfileCard />
                    </div>
                  </div>
                </div>
                {/* <div className='col-lg-1'></div> */}
                <div className='col-lg-8 p-0 m-0 ps-lg-4'>
                  <br />
                  <br />
                  <Home />
                  <Exprience />
                  <Skills />

                  <About />

                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </>
  );
};

export default Layout;
