import React from 'react';
import { Card } from 'react-bootstrap';
import { IoLayersOutline } from 'react-icons/io5';
import { VscWorkspaceTrusted } from 'react-icons/vsc';
import { AiOutlineLayout } from 'react-icons/ai';

const Home = () => {
  return (
    <section className='mt-3 mt-md-0 p-0'>
      <h2 className={'roboto-black gelatine'}>
        <span className={'roboto-black header-text'}>FULLSTACK</span>
        <br />
        <span className={'roboto-black text-secondary header-text'}>
          DEVELOPER
        </span>
      </h2>

      <div className='col-md-9'>
        <p className='roboto-regular text-secondary fs-4'>
          Passionate about creating intuitive and engaging frontend
          applications. Specialize in transforming ideas, dreams and designs
          into beautifully crafted products.
        </p>
      </div>
      <div className='row mt-5 pt-3'>
        <div className='col-2'>
          <h3 className={'roboto-bold stats-text gelatine'}>2+</h3>
          <p className='roboto-medium text-secondary'>Years of Exprience</p>
        </div>
        <div className='col-1'></div>
        <div className='col-2'>
          <h3 className={'roboto-bold  stats-text gelatine'}>10+</h3>
          <p className='roboto-medium text-secondary'>Beautiful Projects</p>
        </div>
      </div>
      <div className='row mt-3 mb-5'>
        <div className='col-md-12 col-xl-6'>
          <Card
            style={{ background: '#e07a5f', height: 250 }}
            className='mt-3 text-white p-3 shadow gelatine d-flex flex-column justify-content-between home-card-my'
          >
            <IoLayersOutline size={30} />
            <p className='fs-3 roboto-medium'>
              Mobile, Website, Desktop Application
            </p>
            <div className='text-end'>
              <VscWorkspaceTrusted className=' nav-Items-my' size={26} />
            </div>
          </Card>
        </div>
        <div className='col-md-12 col-xl-6'>
          <Card
            style={{ background: '#427aa1', height: 250 }}
            className='mt-3 text-white p-3 shadow gelatine d-flex flex-column justify-content-between home-card-my'
          >
            <AiOutlineLayout size={30} />
            <p className='fs-3 roboto-medium'>
              React, Next JS, Node JS, Mongo DB
            </p>
            <div className='text-end'>
              <VscWorkspaceTrusted className=' nav-Items-my' size={26} />
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Home;
